<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center">
      <CCol md="12">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <CInput
                  v-model="email"
                  placeholder="Email"
                  autocomplete="username email"
                  @keyup.enter="login"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  v-model="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="curent-password"
                  @keyup.enter="login"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>

                <router-link class='forgot-password-link' to="/auth/forgot-password">Forgot my password</router-link>

                <p v-if="showError" class="error-msg">Your username or password is incorrect.</p>
                <CRow>
                  <CCol col="6">
                    <CButton @click="login" color="primary" class="px-4 login-btn">Login</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

export default {
  name: 'Login',
  created: () => {
    // console.log(this.$store.getters.isLoggedIn)
  },
  data () {
    return {
      email: '',
      password: '',
      showError: false
    }
  },
  methods: {
    login: function () {
      let email = this.email 
      let password = this.password
      
      this.$store.dispatch('loading');

      this.$store.dispatch('login', { email, password })
     .then(() => { 
       this.$store.dispatch('stopLoading');
       if (this.$store.getters.isAdmin) {
         this.$router.push('/manager/dashboard/admin')
       } else {
         this.$router.push('/manager/bookings')
       }
      })
     .catch((err) => {
        console.log('login error');
        this.$store.dispatch('stopLoading');
        this.showError = true
      })
    }
  }
}
</script>

<style scoped>
  .error-msg {
    color: red;
  }
  .container {
    background-image: url('/img/on-the-rolly-background.jpg') !important;
    background-size: cover;
    max-width: 100%;
  }

  .login-btn {
    margin-top: 20px;
  }

</style>
